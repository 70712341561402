<template>
  <div class="container">
    <h2>Настройки</h2>
    <h3 class="settings-title">Всплывающие модальные окна</h3>
    <ul class="options-list settings-section">
      <li class="options-list__item">
        <label>
          Время существования модального окна с подсказкой
          <Input
            :isEditable="true"
            :isOnlyNumbers="true"
            :maxNumber="60"
            :startValue="infoModalLifeTime"
            @change="setModalLifeTime"
          />
        </label>
      </li>
    </ul>
    <h3 class="settings-title">Сообщения</h3>
    <h4>отображаемые в консоли</h4>
    <ul class="options-list settings-section">
      <li class="options-list__item">
        <label
          ><input
            value="info"
            type="checkbox"
            v-model="consoleMessages"
            @change="updated"
          />
          информация</label
        >
      </li>
      <li class="options-list__item">
        <label
          ><input
            value="warn"
            type="checkbox"
            v-model="consoleMessages"
            @change="updated"
          />
          предупреждения</label
        >
      </li>
      <li class="options-list__item">
        <label
          ><input
            value="error"
            type="checkbox"
            v-model="consoleMessages"
            @change="updated"
          />
          ошибки</label
        >
      </li>
    </ul>
    <h4>отображаемые во всплывающих окнах</h4>
    <ul class="options-list settings-section">
      <li class="options-list__item">
        <label
          ><input
            value="info"
            type="checkbox"
            v-model="modalMessages"
            @change="updated"
          />
          информация</label
        >
      </li>
      <li class="options-list__item">
        <label
          ><input
            value="warn"
            type="checkbox"
            v-model="modalMessages"
            @change="updated"
          />
          предупреждения</label
        >
      </li>
      <li class="options-list__item">
        <label
          ><input
            value="error"
            type="checkbox"
            v-model="modalMessages"
            @change="updated"
          />
          ошибки</label
        >
      </li>
    </ul>

    <Button
      theme="common"
      @click="save"
      :class="{ button__save: true, 'button__save--saved': isSaved }"
    >
      Сохранить
    </Button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Input from "@/components/Input";

export default {
  name: "SettingsPage",
  components: { Input },
  data() {
    return {
      isSaved: true,
      modalLifeTime: 0,
      modalMessages: [],
      consoleMessages: [],
    };
  },
  computed: {
    ...mapState({
      infoModalLifeTime: (state) => state.settingsStore.infoModalLifeTime,
      modalMessageTypes: (state) => state.settingsStore.modalMessageTypes,
      consoleMessageTypes: (state) => state.settingsStore.consoleMessageTypes,
    }),
  },
  created() {
    this.modalLifeTime = this.infoModalLifeTime;
    this.modalMessages = this.modalMessageTypes;
    this.consoleMessages = this.consoleMessageTypes;
  },
  methods: {
    ...mapActions({
      saveSettings: "settingsStore/saveSettings",
    }),
    updated() {
      this.isSaved = false;
    },
    save() {
      this.saveSettings({
        info_modal_life_time: this.modalLifeTime,
        info_modal_message_types: this.modalMessages,
        info_console_message_types: this.consoleMessages,
      });
      this.$pushMessage({
        title: "Информация",
        message: "Настройки сохранены!",
      });
      this.isSaved = true;
      setTimeout(() => {
        this.isSaved = false;
      }, this.modalLifeTime * 1000);
    },
    setModalLifeTime(value) {
      this.modalLifeTime = value;
      this.updated();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.options-list {
  padding: 0;
  list-style: none;
}

.options-sublist {
  list-style: none;
}

input[type="checkbox"] {
  margin-right: 10px;
}

input[type="number"] {
  width: 50px;
}

.options-list__item {
  cursor: pointer;
}

.options-list__item--disabled {
  pointer-events: none;
  color: rgb(180, 180, 180);
}

.settings-title {
  margin-bottom: 14px;
}

.settings-section {
  margin-bottom: 24px;
}

.button__save {
  margin-top: 30px;
  background-color: $erp-action-color;
  color: $erp-light-color;
  cursor: pointer;
  transition: 300ms background-color;
}

.button__save--saved {
  background-color: $erp-accept-color;
  cursor: default;
  pointer-events: none;
  transition: 300ms;
}
</style>
